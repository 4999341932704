// SASS variables
// Design System Color Palette
$white-base: #ffffff;
$black-base: #000000;
$purple-base: #595478;
$blue-accent-base: #4e87a0;
$hover-state: rgba($black-base, 0.05);
$pressed-state: rgba($black-base, 0.15);
$backdrop-color: rgba(0, 0, 0, 0.32);

$gray-base: #505050;
$gray-lighten-20: scale_color($gray-base, $lightness: 20%); // #737373
$gray-lighten-40: scale_color($gray-base, $lightness: 40%); // #969696
$gray-lighten-60: scale_color($gray-base, $lightness: 60%); // #b9b9b9
$gray-lighten-80: scale_color($gray-base, $lightness: 80%); // #dcdcdc
$gray-lighten-90: scale_color($gray-base, $lightness: 90%); // #eeeeee
$gray-lighten-95: scale_color($gray-base, $lightness: 95%); // #f6f6f6
$gray-darken-20: scale_color($gray-base, $lightness: -20%); // #404040
$gray-darken-40: scale_color($gray-base, $lightness: -40%); // #303030
$gray-darken-60: scale_color($gray-base, $lightness: -60%); // #202020

$blue-base: #2b5a87;
$blue-lighten-20: scale_color($blue-base, $lightness: 20%); // #3b7cb9
$blue-lighten-40: scale_color($blue-base, $lightness: 40%); // #689dcf
$blue-lighten-60: scale_color($blue-base, $lightness: 60%); // #9abddf
$blue-lighten-80: scale_color($blue-base, $lightness: 80%); // #cddeef
$blue-lighten-90: scale_color($blue-base, $lightness: 90%); // #e6eff7
$blue-darken-20: scale_color($blue-base, $lightness: -20%); // #22486c
$blue-darken-40: scale_color($blue-base, $lightness: -40%); // #1a3651
$blue-darken-60: scale_color($blue-base, $lightness: -60%); // #112436

$green-base: #74aa50;
$green-lighten-20: scale_color($green-base, $lightness: 20%);
$green-lighten-40: scale_color($green-base, $lightness: 40%);
$green-lighten-60: scale_color($green-base, $lightness: 60%);
$green-lighten-80: scale_color($green-base, $lightness: 80%);
$green-lighten-90: scale_color($green-base, $lightness: 90%);
$green-darken-20: scale_color($green-base, $lightness: -20%);
$green-darken-40: scale_color($green-base, $lightness: -40%);
$green-darken-60: scale_color($green-base, $lightness: -60%);

$yellow-base: #ffb900;
$yellow-lighten-20: scale_color($yellow-base, $lightness: 20%);
$yellow-lighten-40: scale_color($yellow-base, $lightness: 40%);
$yellow-lighten-50: scale_color($yellow-base, $lightness: 50%);
$yellow-lighten-60: scale_color($yellow-base, $lightness: 60%);
$yellow-lighten-80: scale_color($yellow-base, $lightness: 80%);

$orange-base: #dc582a;
$orange-lighten-20: scale_color($orange-base, $lightness: 20%);
$orange-lighten-40: scale_color($orange-base, $lightness: 40%);
$orange-lighten-60: scale_color($orange-base, $lightness: 60%);
$orange-lighten-80: scale_color($orange-base, $lightness: 80%);
$orange-lighten-90: scale_color($orange-base, $lightness: 90%);
$orange-darken-20: scale_color($orange-base, $lightness: -20%);

$red-base: #c80000;
$red-lighten-20: scale_color($red-base, $lightness: 20%);
$red-lighten-40: scale_color($red-base, $lightness: 40%);
$red-lighten-60: scale_color($red-base, $lightness: 60%);
$red-lighten-80: scale_color($red-base, $lightness: 80%);
$red-lighten-90: scale_color($red-base, $lightness: 90%);
$red-darken-20: scale_color($red-base, $lightness: -20%);
$red-darken-40: scale_color($red-base, $lightness: -40%);
$red-darken-60: scale_color($red-base, $lightness: -60%);

$orderve-base: #f6b700;
$orderve-lighten-40: scale_color($orderve-base, $lightness: 40%);
$orderve-darken-60: scale_color($orderve-base, $lightness: -60%);

$focus-state: scale_color($blue-lighten-20, $saturation: 35%);

// Legacy Color Palette
$primary-red: #c80000;
$primary-dark-gray: #505050;
$primary-gray: #767676;
$primary-light-gray: #c8c8c8;
$primary-light-yellow: #fcc11c;
$ui-light-gray: #f3f3f3;
$ui-lighter-gray: #fafafa;
$secondary-gray: #888b8d;
$secondary-light-red: #da4a4a;
$secondary-very-light-red: #f9e5e5;
$secondary-dark-red: #8a2a2b;
$accent-yellow: #eaaa00;
$accent-medium-yellow: #f1cb6e;
$accent-light-yellow: #fcf6e5;
$accent-orange: #ff6200;
$primary-blue: #2b5a87;
$secondary-blue: #407aa0;
$accent-gray: #d1d3d5;
$accent-light-gray: #e5e5e5;
$white: #ffffff;
$focus-outline: #4d90fe;
$secondary-light-gray: #fbfbfb;
$gray: #808080;
$black: #000000;
$secondary-black: #212121;
$hover-filter-gray: #e9e9e9;
$hover-menu-item: rgba(43, 90, 135, 0.1);
$focus-menu-item: rgba(43, 90, 135, 0.04);
$material-tabs-border: rgba(0, 0, 0, 0.12);
$options-menu-hover-color: $hover-filter-gray;
$options-menu-selected-color: $accent-gray;
$link-gray: #afafaf;
$scrollbar-gray: #d8d8d8;
$toggle-primary: $primary-blue;
$toggle-secondary: rgba(43, 91, 136, 0.5);
$helper-light-gray: #d9d9d9;
$light-gray-hr: #dcdcdc;

// colors
$primary-background: $white;
$page-background: $ui-light-gray;
$gfs-primary: $primary-red;
$button-primary-background: $primary-blue;
$button-secondary-background: $white;
$button-secondary-text: $primary-blue;
$button-gradient-blue: linear-gradient(to left, $secondary-blue, $primary-blue);
$button-destructive-text: $primary-red;
$dark-selected-action: $primary-blue;
$primary-warning: $accent-light-yellow;
$urgent-warning: $accent-orange;
$secondary-hover-background: rgba(43, 90, 135, 0.1);
$category-header-hover: $gray;
$scroll-bar-color: $primary-light-gray;
$dark-scroll-bar-color: rgba($primary-dark-gray, 0.6);
$attribute-cell-background: $ui-light-gray;
$hover-icon-background: $ui-light-gray;
$drag-accent: $accent-yellow;
$banner-message-background: #ffdc7f;
$link-separator-border: #979797;
$more-actions-background: rgba(216, 216, 216, 0.3);

// gradients
$lists-header-gradient: linear-gradient(268deg, #686868, $primary-dark-gray);
$container-header-gradient: linear-gradient(
  262deg,
  $secondary-gray,
  $primary-dark-gray
);

// text colors
$dark-product-text: $primary-dark-gray;
$light-product-text: $secondary-gray;
$tab-labels: $primary-dark-gray;
$card-title: $primary-dark-gray;
$card-description: $primary-dark-gray;
$secondary-header: $primary-dark-gray;
$placeholder-color: $primary-light-gray;
$link-color: $primary-blue;
$error-text: $primary-red;
$menu-primary-action: $primary-blue;
$heading-text: $secondary-black;

// border and separator colors
$primary-border: $primary-light-gray;
$urgent-border: $primary-light-yellow;
$secondary-header-border: $accent-gray;
$input-border: $primary-blue;
$destroy-border: $primary-red;
$customer-card-shadow:
  0 1px 1px 0 rgba(0, 0, 0, 0.14),
  0 2px 1px -1px rgba(0, 0, 0, 0.12),
  0 1px 3px 0 rgba(0, 0, 0, 0.2);

// border and separator constants
$box-shadow:
  0 4px 4px 0 rgba(0, 0, 0, 0.1),
  0 0 4px 0 rgba(0, 0, 0, 0.05);
$box-shadow-top: 0 -1px 10px 0 rgba(0, 0, 0, 0.2);
$naoo-padding-bottom: 24px;
$no-box-shadow:
  0 0 0 0 rgba(0, 0, 0, 0.2),
  0 0 0 0 rgba(0, 0, 0, 0.14),
  0 0 0 0 rgba(0, 0, 0, 0.12);
$thin-box-shadow:
  0 2px 2px 0 rgba(0, 0, 0, 0.1),
  0 0 2px 0 rgba(0, 0, 0, 0.05);
$medium-box-shadow:
  0 3px 3px 0 rgba(0, 0, 0, 0.1),
  0 0 3px 0 rgba(0, 0, 0, 0.05);

// banner
$warning-banner-background: rgba($primary-red, 0.15);

// mobile colors
$mobile-result: $primary-dark-gray;
$mobile-header-border: $primary-light-gray;
$mobile-header-background: $ui-light-gray;
$mobile-sort-filter-background: $ui-light-gray;
$mobile-quantity-pillbox-background: $ui-light-gray;
$mobile-taxonomy-filter-background: $ui-lighter-gray;

// breakpoints
$screen-x-small: 300px;
$screen-small: 360px;
$screen-small-medium: 415px;
$screen-medium-small: 600px;
$screen-order-method-modal-height: 600px;
$screen-medium: 900px;
$screen-medium-large: 1000px;
$screen-tablet: 1111px;
$screen-tablet-large: 1150px;
$screen-large: 1280px;
$screen-x-large: 1600px;

// new breakpoints
$screen-min-mobile: 375px;
$screen-max-mobile: 767px;
$screen-min-tablet: 768px;
$screen-max-tablet: 1279px;
$screen-min-desktop: 1280px;

// font attributes
$light-weight: 300;
$regular-weight: 400;
$medium-weight: 500;
$bold-weight: 700;
$heavy-weight: 900;

// font sizes
$h1-desktop-font-size: 36px;
$h1-mobile-font-size: 28px;
$h2-desktop-font-size: 28px;
$h2-mobile-font-size: 24px;
$input-font-size: 16px; // minimum font size to avoid zooming on mobile

// widths for material row
$material-ordering-content-width: 425px;
$material-ordering-par-content-width: 500px; // ordering-content-width + par-quantity-width
$material-ordering-loyalty-content-width: 505px; // ordering-content-width + par-quantity-width
$material-ordering-no-loyalty-label-content-width: 406px;
$material-commodity-width: 120px; // commodity-width
$material-commodity-footer-width: 464px; // commodity-footer-width
$material-par-quantity-width: 130px; // par-width + inventory-width
$material-par-quantity-mobile-width: 122px; // par-quantity-width - 8px margin
$material-loyalty-points-width: 80px;
$material-size-width: 65px;
$material-price-width: 70px;
$material-info-and-price-section-min-width: 90px;
$material-portion-price-width: 75px;
$material-par-width: 60px;
$material-inventory-quantity-width: 70px;
$material-quantity-width: 40px;
$material-total-price-width: 86px;
$material-triple-dot-width: 42px;
$material-triple-dot-mobile-width: 30px;

// flex sizes for saved and submitted cart row
$full-width: 100%;
$shipment-details: 70%;
$delete-button-container: 40%;
$last-updated-content-screen-large: 22%;
$shipment-info-content-screen-large: 23%;
$cart-status-content-screen-large: 20%;
$button-container-content-screen-large: 35%;

// widths for customer unit selection
$customer-unit-customer-info-small-width: 30%;
$customer-unit-customer-info-medium-width: 40%;
$customer-unit-customer-info-large-width: 50%;

$customer-unit-cart-info-small-width: 70%;
$customer-unit-cart-info-medium-width: 60%;
$customer-unit-cart-info-large-width: 50%;

$customer-unit-customer-name-width: 75%;
$customer-unit-customer-number-width: 25%;

$customer-unit-active-items-flex: 2;
$customer-unit-saved-carts-flex: 1;
$customer-unit-submitted-for-approval-flex: 2;
$customer-unit-punched-out-carts-flex: 1;

// Z-index centralization
$zindex: (
  modal: 9000,
  overlay: 8000,
);
