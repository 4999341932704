@use '@angular/material' as mat;
@import 'stylesheets/variables';
@import 'stylesheets/functions';
@import 'stylesheets/mixins/shared';
@import 'stylesheets/material/naoo-palettes';
@import '@angular/material/prebuilt-themes/indigo-pink.css';

@include mat.core;

// Material Themes
$gray-blue-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $gray-palette,
      accent: $blue-palette,
    ),
  )
);

$blue-gray-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $blue-palette,
      accent: $gray-palette,
    ),
  )
);

$primary-red-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $primary-red-palette,
      accent: $primary-red-palette,
    ),
  )
);

$gray-yellow-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $gray-palette,
      accent: $yellow-palette,
    ),
  )
);

// Apply material theme overrides
@include mat.core-theme($gray-blue-theme);
@include mat.card-theme($gray-blue-theme);
@include mat.progress-spinner-theme($primary-red-theme);
@include mat.form-field-theme($gray-blue-theme);
@include mat.input-theme($gray-blue-theme);
@include mat.select-theme($gray-blue-theme);
@include mat.slide-toggle-theme($gray-blue-theme);
@include mat.radio-theme($gray-blue-theme);
@include mat.checkbox-theme($gray-blue-theme);
@include mat.stepper-theme($blue-gray-theme);
@include mat.datepicker-theme($primary-red-theme);
@include mat.tabs-theme($gray-blue-theme);
@include mat.progress-bar-theme($primary-red-theme);
@include mat.snack-bar-theme($gray-yellow-theme);
@include mat.menu-theme($blue-gray-theme);
@include mat.button-theme($gray-blue-theme);
@include mat.chips-theme($blue-gray-theme);

@mixin naoo-custom-dialog($max-width: auto, $max-height: auto) {
  border-radius: 10px;
  text-align: center;
  max-width: $max-width;
  max-height: $max-height;
}

.mat-calendar-body-cell-content {
  font-weight: $medium-weight;
}

.mat-mdc-button-persistent-ripple,
.mat-mdc-button-ripple {
  display: none;
}

.mat-mdc-form-field-focus-overlay {
  --mat-form-field-state-layer-color: $white;
}

.cdk-overlay-pane {
  max-width: 100% !important;

  .mat-mdc-select-panel {
    min-width: fit-content;
  }

  .mat-mdc-autocomplete-panel {
    width: 90%;
    max-height: none;
    @media (min-width: $screen-min-mobile) and (max-width: $screen-max-tablet) {
      width: 100%;
      box-shadow: none;
      margin-top: 18px;
      max-height: 100%;
    }
  }
}

.mat-mdc-tab-group {
  .mat-mdc-tab-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }

  .mat-mdc-tab-body-wrapper {
    flex-grow: 1;
    padding-top: 16px;
  }

  &.mat-primary {
    .mat-mdc-tab {
      text-transform: uppercase;

      &:not(.mat-tab-disabled) {
        @include focus-indicator;

        &:focus {
          outline-offset: -2px;
          background-color: transparent;
        }
      }
    }

    .mdc-tab-indicator__content--underline {
      --mat-tab-header-active-focus-indicator-color: #c80000;
      --mat-tab-header-inactive-focus-indicator-color: #c80000;
      --mat-tab-header-active-hover-indicator-color: #c80000;
      --mdc-tab-indicator-active-indicator-color: #c80000;
    }
  }
}

.mdc-form-field button:focus,
.mat-calendar-body-cell-container button:focus {
  outline: none;
}

.mat-mdc-dialog-container {
  border-radius: 10px;
  overflow-y: auto;
  overflow-x: hidden;

  .mdc-dialog__container {
    width: inherit;
  }
}

.mat-expansion-panel-body {
  padding: 0 !important;
}

.mat-mdc-menu-panel {
  max-width: 320px !important;
}

.mat-mdc-menu-content {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.mat-mdc-menu-ripple {
  display: none !important;
}

.mat-mdc-menu-item.submenu-item span.mdc-list-item__primary-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

span.mat-mdc-menu-item-text {
  display: inherit;
  align-items: center;
  font-weight: $medium-weight;
}

.mdc-list-item.mat-mdc-menu-item span.mdc-list-item__primary-text {
  display: flex;
  align-items: center;
  font-weight: $medium-weight;
  width: 100%;
}

.mat-mdc-menu-item .mat-mdc-menu-item-text {
  --mat-menu-item-label-text-weight: $medium-weight;
}

.mat-mdc-option .mdc-list-item__primary-text {
  font-weight: $medium-weight !important;
}

.mat-mdc-icon-button.mat-mdc-button-base {
  padding: 0;
  width: auto;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  --mdc-filled-text-field-container-color: $white;
  padding: unset;
}

.mat-mdc-select {
  &:not(.mat-mdc-select-disabled) {
    @include focus-indicator;
  }
}

.mat-mdc-select-arrow,
.mat-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
  color: $secondary-black;
  margin-left: 4px;
}

.mat-mdc-select-trigger {
  vertical-align: middle;
}

.mat-mdc-snack-bar-handset .mat-mdc-snack-bar-container {
  @media screen and (max-width: $screen-medium) {
    //This adds more space for Gordon Now
    width: 75%;
  }
}

.mat-step-header {
  .mat-step-label {
    .completed-step {
      text-decoration: underline;
    }
  }
}

@media print {
  .mat-mdc-tab-body {
    width: 100%;
  }

  mat-icon {
    &.green {
      @include svg-color($green-base);
    }

    &.yellow-warning-icon {
      @include svg-color($yellow-base);
    }

    &.red {
      @include svg-color($red-base);
    }

    &.gray {
      @include svg-color($gray-base);
    }

    &.purple {
      @include svg-color($purple-base);
    }
  }
}

/* Animate items as they're being sorted. */
.cdk-drop-list-dragging .cdk-drag {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

/* Animate an item that has been dropped. */
.cdk-drag-animating {
  transition: transform 300ms cubic-bezier(0, 0, 0.2, 1);
}
