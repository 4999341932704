@import 'stylesheets/variables';
@import 'stylesheets/functions';
@import 'stylesheets/mixins/shared';

@mixin naoo-custom-dialog($max-width: auto, $max-height: auto) {
  max-width: $max-width;
  max-height: $max-height;
}

.naoo-mat-dialog .mat-mdc-dialog-container {
  @include naoo-custom-dialog(390px);
}

.naoo-delivery-date-picker-modal .mat-mdc-dialog-container {
  @include naoo-custom-dialog(576px, calc(100vh - 110px));
  @media screen and (max-width: $screen-small) {
    .primary-button {
      width: 200px !important;
    }
  }
}

.naoo-credit-card-modal .mat-mdc-dialog-container {
  @include naoo-custom-dialog(910px, 581px);
  width: 90vw;
  height: 80vh;
}

.naoo-import-order-modal,
.naoo-bulk-add-modal {
  .mat-mdc-dialog-container {
    @include naoo-custom-dialog(910px, 630px);
    text-align: left;
    width: 80vw;

    @media screen and (max-width: $screen-tablet-large) {
      min-width: 100%;
      min-height: 100%;
    }
  }

  @media screen and (max-width: $screen-medium-small) {
    width: 100vw;
    height: 100vh;
  }
}

.naoo-copy-guide-modal {
  @media (max-width: $screen-medium) {
    max-width: initial !important;
    max-height: initial !important;
  }

  .mat-mdc-dialog-container {
    @include naoo-custom-dialog(910px, 80vh);
    width: 80vw;
    text-align: left;

    @media (max-width: $screen-medium) {
      width: calc(100vw - 32px);
      max-height: calc(100vh - 64px);
    }
  }
}

.naoo-import-guide-modal .mat-mdc-dialog-container {
  @include naoo-custom-dialog(910px, 630px);
  width: 90vw;
}

.naoo-re-order-modal .mat-mdc-dialog-container {
  @include naoo-custom-dialog(910px, 581px);
  width: 90vw;
  height: 80vh;
}

.naoo-order-guide-changes-modal {
  @media screen and (max-width: $screen-small-medium) {
    min-width: 100%;
    height: 100%;
  }

  .mat-mdc-dialog-container {
    @include naoo-custom-dialog(910px, 564px);
    width: 90vw;

    @media screen and (max-width: $screen-small-medium) {
      height: 100%;
    }
  }

  header {
    @media screen and (max-width: $screen-small-medium) {
      position: absolute;
    }
  }
}

.naoo-edit-order-modal .mat-mdc-dialog-container {
  @include naoo-custom-dialog(910px, 581px);
  width: 90vw;

  @media screen and (max-width: $screen-medium-small) {
    width: 100vw;
    height: 100vh;
    max-height: 100%;
    border-radius: 0;
  }
}

.naoo-material-overallocation-modal .mat-mdc-dialog-container,
.naoo-material-warning-modal .mat-mdc-dialog-container {
  @include naoo-custom-dialog(910px, 90vh);
  width: 90vw;

  @media screen and (max-width: $screen-medium-small) {
    width: 100vw;
    height: 100vh;
    max-height: 100%;
    border-radius: 0;
  }
}

.naoo-material-related-container {
  @media screen and (max-width: $screen-small-medium) {
    min-width: 100%;
    height: 100%;
  }

  .mat-mdc-dialog-container {
    @include naoo-custom-dialog(910px, auto);
  }
}

.naoo-quick-add-learn-modal .mat-mdc-dialog-container {
  @include naoo-custom-dialog(606px, 385px);
  width: 90vw;
  height: auto;
}

.naoo-support-contact-modal .mat-mdc-dialog-container {
  @include naoo-custom-dialog(604px, auto);

  @media screen and (max-width: $screen-small) {
    width: 100vw;
    height: 100vh;
  }
}

.naoo-custom-guide-help-modal .mat-mdc-dialog-container {
  @include naoo-custom-dialog(604px, 534px);
}

.naoo-export-modal .mat-mdc-dialog-container {
  @include naoo-custom-dialog(910px, auto);

  .mat-mdc-dialog-title {
    display: flex;
    padding: 0;
    margin-bottom: 0;
    font-size: em(14px);
  }

  .mat-mdc-dialog-content {
    padding-left: 0;
    padding-right: 0;
    height: 459px;
  }
}

.generic-help-modal .mat-mdc-dialog-container {
  @include naoo-custom-dialog;
  width: 604px;
  height: 534px;

  @media screen and (max-width: $screen-small-medium) {
    width: 100vw;
    height: 100vh;
  }
}

.product-attributes-modal .mat-mdc-dialog-container {
  @include naoo-custom-dialog(604px, auto);
}

naoo-cart-summary-container {
  // these matTab overflow settings are critical for the orderSummaryHeader to float
  .mat-mdc-tab-group-dynamic-height .mat-mdc-tab-body-content {
    overflow: unset !important;
  }

  .mat-mdc-tab-body {
    overflow: unset !important;
  }

  .mat-mdc-tab-body-wrapper {
    overflow: unset !important;
  }
}

.changes-option {
  //naoo-order-guide-changes-menu
  span.mdc-list-item__primary-text {
    display: flex;
    justify-content: space-between;
    width: inherit;
  }
}

.guide-changes-menu {
  //naoo-order-guide-changes-menu
  .mat-mdc-menu-content {
    div:not(.changes-menu-header):not(.changes-option):not(.guide-name) {
      padding-top: 11px;
      max-height: 300px;
      overflow-y: scroll;
    }
  }
}

.custom-guide.mat-mdc-menu-item span.mdc-list-item__primary-text {
  //naoo-add-to-guide-menu
  //naoo-add-to-custom-guide
  font-weight: $regular-weight;
}

.button-icon {
  //naoo-add-to-guide-menu
  //naoo-add-to-custom-guide
  //naoo-order-guide-changes-menu
  @include svg-color($gray-base);

  mat-icon {
    display: flex;
    max-width: 10px;
    max-height: 10px;
  }
}

.add-to-custom-guide-menu .mat-mdc-menu-content {
  //naoo-add-to-custom-guide
  min-width: 285px;
}

naoo-material-ordering-info {
  .uom-select-field {
    .mat-mdc-select {
      &.highlight-quantity {
        .mat-mdc-select-value-text {
          color: $white-base !important;
        }

        .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow {
          color: $white-base !important;
        }
      }

      .mat-mdc-select-value-text {
        max-width: 55px;
        min-width: 32px;
        display: table-cell;
        font-size: em(14px);
        font-weight: $medium-weight;
        text-align: right;
        color: $gray-base;

        .uom-select-trigger {
          @include clamp;
          text-transform: capitalize;
        }
      }
    }
  }
}

naoo-rename-category-modal,
naoo-create-category-modal,
naoo-create-custom-guide-modal,
naoo-rename-custom-guide-modal,
naoo-create-order-guide-category-modal {
  .mat-mdc-form-field {
    width: 100%;
  }
}

naoo-edit-order-guide,
naoo-edit-custom-guide,
.naoo-guide-sort-by-select,
.naoo-material-view-select,
.naoo-material-uom-select,
.naoo-material-cart-sort-by {
  .mdc-line-ripple {
    display: none;
  }

  .mat-mdc-form-field-infix {
    width: inherit;
  }
}

naoo-guides-header .mobile-category-select-form {
  .mdc-line-ripple {
    display: none;
  }
}

.naoo-material-view-option,
.naoo-material-uom-option,
.naoo-material-cart-sort-by-option {
  font-weight: $medium-weight;
}

.white-indicator .mat-expansion-indicator {
  //naoo-error-materials
  &::after {
    color: $white-base;
  }
}

.expansion-panel-header-large-indicator .mat-expansion-indicator {
  //naoo-error-materials
  margin-right: 4px;

  &::after {
    color: $black;
    border-width: 0 3px 3px 0;
    padding: 5px;
    margin-bottom: 8px;
  }
}

naoo-generic-expansion-panel .generic-expansion-header {
  .mat-expansion-indicator {
    display: flex;
    margin-right: 4px;

    &::after {
      color: $white;
      border-width: 0 3px 3px 0;
      padding: 5px;
      margin-bottom: 8px;
    }
  }

  .mat-content {
    align-items: center;
  }

  .mat-expansion-panel-header-title {
    flex-grow: 0;
    margin-right: 0;

    .mat-icon {
      width: 20px;
      height: 20px;
      min-width: 20px;
      min-height: 20px;
      margin: auto 0;

      &.green {
        @include svg-color($green-base);
      }

      &.red {
        @include svg-color($red-base);
      }

      &.yellow {
        @include svg-color($yellow-base);
      }

      &.black {
        @include svg-color($secondary-black);
      }
    }
  }

  &.gray {
    .mat-expansion-indicator {
      &::after {
        color: $secondary-black;
      }
    }
  }
}

naoo-active-cart-header .active-cart-header-container {
  .secondary-header-content {
    .delivery-schedule-content {
      .calendar-image {
        //naoo-select-order-method-widget
        width: 24px;
        height: 24px;
        color: $primary-blue;
      }
    }
  }
}

naoo-selectable-button {
  .mdc-button__label {
    flex-grow: 1;
  }
}

naoo-cart-review-store-error-header {
  .mdc-button__label {
    flex-grow: 1;
  }

  .single-pickup-button {
    &:not(:disabled):not(.selected):hover {
      .option-group {
        .option-icon {
          @include svg-color($red-base);
        }
      }
    }

    &:disabled {
      .option-group {
        .option-icon {
          @include svg-color($gray-base);
        }
      }
    }
  }

  .banner-icon {
    &.success-icon {
      @include svg-color($green-base);
    }

    &.error-icon {
      @include svg-color($red-base);
    }

    &.warning-icon {
      @include svg-color($yellow-base);
    }
  }
}

naoo-cart-review-missed-cutoff-header {
  .mat-mdc-checkbox {
    margin-left: -11px;

    label {
      font-size: em(16px);
      font-weight: $medium-weight;
      color: $blue-base;
    }
  }
}

naoo-material-list virtual-scroller {
  &.centered-grid {
    @media screen and (max-width: $screen-medium) {
      .scrollable-content {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }
    }
  }
}

naoo-filter label {
  &:hover {
    background-color: $hover-filter-gray;
  }
}

naoo-filter-group .mat-expansion-panel-content {
  padding-right: 24px;
}

naoo-store-purchase-details-header .icon-wrapper {
  .gray {
    @include svg-color($gray-base);
  }
}

naoo-multi-customer-select {
  .mat-mdc-checkbox {
    width: 100%;
  }

  .mat-grid-tile {
    .mat-figure {
      left: 16px;
      justify-content: flex-start;
    }
  }

  .mat-mdc-checkbox.select-all {
    .mdc-checkbox
      .mdc-checkbox__native-control:enabled:not(:checked):not(
        :indeterminate
      ):not([data-indeterminate='true'])
      ~ .mdc-checkbox__background {
      background-color: $white;
    }

    .mdc-checkbox
      .mdc-checkbox__native-control:enabled:checked
      ~ .mdc-checkbox__background {
      background-color: $white;
    }

    --mdc-checkbox-selected-checkmark-color: #2b5a87;

    label {
      color: $white-base;
      font-weight: $medium-weight;
      text-transform: uppercase;
    }
  }
}

.docket-tooltip {
  //naoo-generic-docket-header
  white-space: pre-wrap;
}

naoo-critical-item-helper-modal .mat-icon {
  @include svg-color($primary-light-gray);
}

naoo-cost-summary-print-content .mat-icon {
  @include svg-color($black-base);
}

naoo-edit-order-guide,
naoo-edit-custom-guide {
  .edit-category-buttons .mat-icon {
    use {
      fill: $blue-base;
    }
  }
}

.drop-ship-option.mat-mdc-option {
  //naoo-cart-review-drop-ship-header
  font-size: em(12px);

  .mdc-list-item__primary-text {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}

naoo-cart-review-drop-ship-header {
  .freight-content,
  .address-content {
    .mat-mdc-select-trigger {
      display: flex;
      justify-content: space-between;
      font-size: em(12px);

      .mat-mdc-select-value .mat-mdc-select-value-text {
        display: flex;
        width: 100%;

        .address-display-trigger,
        .freight-display-trigger {
          width: 100%;
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
}

naoo-material-ordering-tab .tab-content {
  .mat-mdc-tab-header {
    @media screen and (max-width: $screen-medium) {
      padding: 0 16px;
    }
  }

  .mat-mdc-tab {
    height: 32px;
    padding: 0 8px;
    min-width: 62px;
    box-sizing: border-box;
  }

  .mat-mdc-tab-group {
    @media screen and (max-width: $screen-medium) {
      min-width: 100%;
    }
  }

  .mdc-tab__content {
    text-transform: capitalize;
  }
}

.naoo-loading-dialog .mat-mdc-dialog-container {
  @include naoo-custom-dialog;

  //naoo-submit-loading-modal
  width: 350px;
  height: 200px;
}

.naoo-order-method-modal {
  .mat-horizontal-stepper-header-container {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  }

  @media screen and (max-width: $screen-tablet-large),
    (max-height: $screen-order-method-modal-height) {
    height: 100%;
    width: 100%;
    max-height: 100%;
    max-width: 100% !important;
  }
}

.naoo-order-method-modal,
.naoo-cart-review-stepper {
  .mat-horizontal-stepper-header {
    height: 56px;
  }

  .mat-stepper-horizontal-line {
    flex: inherit;
  }
}

naoo-search-results-content {
  .mat-mdc-tab-body-content {
    overflow-y: auto;
    overflow-x: hidden;
  }
}

naoo-invoices-credits {
  .invoice-type .mat-icon {
    height: 17px;
    padding-right: 11px;
  }
}

.naoo-cart-review-stepper {
  .mat-step-header[aria-labelledby='clickable'] {
    cursor: pointer;
  }

  .mat-horizontal-stepper-header-container {
    margin-bottom: 24px;
    padding-left: 16px;
  }
}

.select-date-step {
  //naoo-select-date-step-ispu
  //naoo-select-date-step-express
  //naoo-select-date-step-truck
  mat-calendar {
    margin-top: -16px;
  }

  .mat-calendar-table-header {
    color: #212121;
  }

  .invalid-date {
    .mat-calendar-body-cell-content.mat-focus-indicator {
      box-shadow: 0 0 0 1px #979797;
    }
  }

  .mat-calendar-body-label {
    padding: unset !important;
    opacity: 0;
  }

  .mat-calendar-body-today.mat-calendar-body-selected {
    box-shadow: none;
  }

  .mat-calendar-body-today:not(.mat-calendar-body-selected) {
    border: 0 none;
  }
}

naoo-delivery-date-picker-calendar {
  mat-calendar {
    width: 258px;
    margin-top: -16px;
    @media screen and (max-width: $screen-medium) {
      width: calc(100% - 32px);
      margin: 0 16px 0 14px;
    }
  }

  mat-calendar .mat-calendar-content {
    padding: unset;
  }

  .mat-calendar-body-label {
    padding: unset !important;
    opacity: 0;
  }

  mat-calendar-header .mat-calendar-header {
    padding: unset;
  }

  .mat-calendar-table {
    .mat-calendar-table-header-divider {
      height: 12px;

      &::after {
        left: 5px;
        right: 5px;
      }
    }
  }

  .mat-calendar-body-today:not(.mat-calendar-body-selected) {
    border: 0 none;
  }
}

.generic-modal {
  .mdc-dialog .mdc-dialog__content {
    padding: 0 0 24px 0;
  }
}

.naoo-go-points {
  // check redemption modal
  .mat-mdc-dialog-container {
    .mat-mdc-dialog-content {
      height: 100%;
    }

    @media screen and (max-width: $screen-tablet-large) {
      height: 100%;
    }
  }

  .mdc-dialog .mdc-dialog__content {
    padding: 0 0 24px 0;
  }

  .message {
    color: #2b5b88;
    font-weight: $medium-weight;
    padding-bottom: 14px !important;
  }

  .modal-image {
    width: 150px;
    height: 150px;
    margin: 50px 0 20px 0;
  }
}

.naoo-ltl-modal {
  height: fit-content;

  .mdc-dialog .mdc-dialog__content {
    padding: 0 0 24px 0;
  }

  h4 {
    margin-bottom: 0;
    margin-top: 20px;
    color: #2b5b88;
  }

  .message {
    font-weight: $light-weight;
    padding-bottom: 14px !important;
    text-align: left;
  }

  ul {
    margin-top: 0;
    text-align: left;
  }

  .mat-mdc-dialog-container .mat-mdc-dialog-content {
    font-size: 0.75em;

    @media screen and (max-width: $screen-max-tablet) {
      width: 700px;
    }

    @media screen and (max-width: $screen-medium-small) {
      width: 340px;
      font-size: 0.625em;
      max-height: fit-content;
      ul {
        padding-left: 30px;
      }
    }
  }
}

.product-detail-full-image {
  // naoo-image-carousel full-size modal
  .mat-mdc-dialog-container {
    @include naoo-custom-dialog;
  }

  @media screen and (max-width: $screen-tablet-large) {
    .modal-image {
      width: 90%;
    }
  }
}

.product-details-expansion {
  //naoo-product-secondary-details
  .mat-expansion-indicator::after {
    color: $white-base;
  }

  .mat-expansion-panel-body {
    margin: 20px 9px 20px 15px;
  }

  .mat-expansion-panel-content {
    .mat-expansion-panel-body {
      font-size: 16px;
    }
  }
}

naoo-order-details {
  .cell {
    .gray {
      fill: $gray-base;
    }
  }
}

.line-broken-tooltip {
  // naoo-store-display
  white-space: pre-line;
}

naoo-search-bar {
  .mdc-line-ripple {
    display: none;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    padding-left: 26px;
    height: 44px;
    @media (min-width: $screen-min-mobile) and (max-width: $screen-max-mobile) {
      padding-left: 40px;
      width: 110%;
    }
    @media (min-width: $screen-min-tablet) and (max-width: $screen-medium) {
      padding-left: 10px;
      width: 104%;
    }
  }
}
