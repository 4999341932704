// You can add global styles to this file, and also import other style files
@import 'stylesheets/bootstrap.min.css';
@import 'stylesheets/bootstrap-theme.min.css';
@import 'stylesheets/functions';
@import 'stylesheets/variables';
@import 'stylesheets/mixins/shared';

html {
  height: 100%;
}

button {
  @include focus-indicator;
}

:focus,
:focus-visible {
  // webkit browsers will use -webkit-focus-ring-color, others will use $focus-outline
  outline-color: $focus-outline;
  outline-color: -webkit-focus-ring-color;
}

body {
  background-color: $page-background;
  font-family: 'Roboto', 'Arial', sans-serif;
  font-weight: $regular-weight;
  height: 100%;

  &:before {
    content: 'DESKTOP';
    display: none; // Prevent from displaying.
  }

  &.alternate {
    background-color: $primary-background;
  }

  @media (max-width: $screen-medium) {
    &:before {
      content: 'MOBILE';
    }
  }
}

// Hides border around video content for iOS/Webkit (Safari)
video {
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
}

@media print {
  body {
    position: relative;
    padding-bottom: 0;
  }

  ::-webkit-scrollbar {
    width: 0 !important;
  }
}
