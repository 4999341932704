@import '../variables';

@mixin search-bar {
  border-radius: 25px;
  box-shadow: none;
  border: solid 1px $primary-border;

  &::-webkit-input-placeholder {
    // Chrome/Opera/Safari
    color: $placeholder-color;
  }

  &::-moz-placeholder {
    // Firefox 19+
    color: $placeholder-color;
  }

  &:-ms-input-placeholder {
    // IE 10+
    color: $placeholder-color;
  }

  &:-moz-placeholder {
    // Firefox 18-
    color: $placeholder-color;
  }
}

@mixin clamp {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin multi-line-clamp($line-height, $total-lines) {
  @include clamp;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $total-lines;
  line-height: $line-height;
  display: -webkit-box;
  white-space: normal;
  max-height: 100%;
}

@mixin word-break {
  word-wrap: break-word;
  overflow-wrap: anywhere;
}

@mixin focus-indicator {
  &:focus {
    // webkit browsers will use -webkit-focus-ring-color, others will use $focus-outline
    outline: auto 2px $focus-state;
    outline: auto 2px -webkit-focus-ring-color;
    text-decoration: none;
  }
}

@mixin disabled-link {
  pointer-events: none;
  cursor: default;
  color: $gray-lighten-40;
}

@mixin naoo-card {
  border-radius: 3px;
  box-sizing: border-box;
  box-shadow: $thin-box-shadow;
  background-color: $primary-background;
}

@mixin custom-focus-outline($background-color) {
  @include focus-indicator;
  border-radius: 50%;
  &:focus {
    background-color: $background-color;
  }
  &:hover {
    background-color: $options-menu-hover-color;
  }
}

@mixin material-flag($background-color, $text-color, $border-color) {
  @include focus-indicator;
  background-color: $background-color;
  .material-flag-title {
    color: $text-color;
  }

  &[tooltip] {
    position: relative;
  }

  &:hover,
  &:active {
    border: 1px solid $border-color;
  }

  &[tooltip]::before {
    border-color: $background-color transparent transparent transparent;
  }

  &[tooltip]::after {
    background: $background-color;
    color: $text-color;
    border: 1px solid $border-color;
  }
}

@mixin purchase-detail-cell {
  display: flex;
  align-items: center;
  font-weight: $regular-weight;
  margin-right: 16px;

  &.item {
    order: 1;
    flex: 6;
    text-overflow: ellipsis;
  }

  &.points {
    order: 2;
    flex: 1;

    .loyalty-icon {
      padding-right: 4px;
    }
  }

  &.unit-size {
    order: 3;
    flex: 1;
  }

  &.quantity {
    order: 4;
    flex: 0.5;
  }

  &.total {
    order: 5;
    flex: 1;
    margin-right: 0;
  }

  @media screen and (max-width: $screen-medium) {
    margin-right: 0;
  }
}

@mixin reset-button {
  background: none;
  border: 0;
  padding: 0;
}

@mixin svg-color($color) {
  svg,
  path {
    fill: $color;
  }
  use {
    fill: $color;
  }
}
